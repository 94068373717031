:root {
    --header: rgba(70,75,65,1);
    --page-head: #b3aea973;
    --title-background: #fafafaed;
}

.titlepage_container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
/* Color over title page */
.titlepage_container::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--page-head);
    z-index: 100;
    content: '';
    padding:0;
    margin: 0;
    filter: blur(1px);
    pointer-events: none;
}

.titlepage_imageholder {
    min-height: 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
    line-height: 0;
    position: relative;
}

.titlepage_container-header_container {
    display: inline;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 110;
    pointer-events: none;
}

.titlepage_container-header {
    background-color: var(--title-background);
    width: fit-content;
    margin: auto;
    padding: 0 1em 0 1em;
    border-radius: 3px;
    font-size: large;
    font-family: Raleway;
    pointer-events: none;
}

.titlepage_container-header h1 {
    font-size: 4rem;
    margin-bottom: 0;
}

.titlepage_container-header p {
}

body, html {
    position: fixed;
}
