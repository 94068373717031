.page-container {
    min-width: 100vw;
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: beige;
}

.page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
}
