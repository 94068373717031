:root {
    --hover-expand: 5%;
    --hover-transition-time: .1s;
}

.title-image_container {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: inline-block;
    z-index: 1;
    position: relative;
}

.title-image_container:hover {
    overflow: visible;
    z-index: 2;
}

.title-image_container:hover>img {
    min-width: calc(100% + var(--hover-expand)) !important;
    min-height: calc(100% + var(--hover-expand)) !important;
    z-index: 2;
    border: 1px solid #8A8A8A;
    top: calc( -1 * var(--hover-expand) / 2);
    left: calc( -1 * var(--hover-expand) / 2);
}

.title-image_img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    padding: 0;
    margin: 0;
    z-index: 1;
    display: inline-block;
    position:absolute;
    top: 0;
    left: 0;
    transition: min-width var(--hover-transition-time),
                min-height var(--hover-transition-time),
                top var(--hover-transition-time),
                left var(--hover-transition-time);
    transition-timing-function: linear;
}
